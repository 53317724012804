export default {
  maxLength: {
    id: 'address_fields_invalid_length',
    defaultMessage: 'This field is too long',
  },
  minLength: {
    id: 'address_fields_invalid_min_length',
    defaultMessage: 'Enter at least 2 characters',
  },
  invalidCharacters: {
    id: 'address_fields_invalid_characters',
    defaultMessage:
      "The only special characters allowed are hyphen (-), apostrophe ('), and space.",
  },
  invalidInteger: {
    id: 'address_fields_invalid_integer',
    defaultMessage: 'This field cannot contain numeric values',
  },
}
