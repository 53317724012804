<template>
  <div>
    <button
      v-if="!shouldDisplayField"
      :id="buttonId"
      class="flex cursor-pointer items-center bg-transparent"
      :style="{ color: '#3F55BF' }"
      type="button"
      @click="displayField"
    >
      <IconPlus /><span class="body-2-bold text-left">{{ label }}</span>
    </button>
    <slot v-if="shouldDisplayField" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { IconPlus } from '@ds/icons/IconPlus'

export type AddressOptionalFieldProps = {
  label: string
  buttonId: string
  value?: string
  displayed?: boolean
}

const props = withDefaults(defineProps<AddressOptionalFieldProps>(), {
  value: '',
  displayed: false,
})

const shouldDisplayField = ref(props.displayed || !isEmpty(props.value))

function displayField() {
  shouldDisplayField.value = true
}
</script>
