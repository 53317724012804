export interface Field {
  order: number
  shouldDisplay?: boolean
}

export enum Fields {
  city = 'city',
  company = 'company',
  country = 'country',
  firstName = 'firstName',
  firstNamePronunciation = 'firstNamePronunciation',
  lastName = 'lastName',
  lastNamePronunciation = 'lastNamePronunciation',
  name = 'name',
  namePronunciation = 'namePronunciation',
  phone = 'phone',
  postalCode = 'postalCode',
  postalCodeAutocomplete = 'postalCodeAutocomplete',
  publicCompanyName = 'publicCompanyName',
  stateOrProvince = 'stateOrProvince',
  street = 'street',
  street2 = 'street2',
  streetAutocomplete = 'streetAutocomplete',
}

export interface FieldsNameStyle {
  [Fields.city]: Field
  [Fields.company]: Field
  [Fields.country]: Field
  [Fields.firstName]: Field
  [Fields.firstNamePronunciation]: Field
  [Fields.lastName]: Field
  [Fields.lastNamePronunciation]: Field
  [Fields.name]: Field
  [Fields.namePronunciation]: Field
  [Fields.phone]: Field
  [Fields.postalCode]: Field
  [Fields.postalCodeAutocomplete]: Field
  [Fields.publicCompanyName]: Field
  [Fields.stateOrProvince]: Field
  [Fields.street]: Field
  [Fields.street2]: Field
  [Fields.streetAutocomplete]: Field
}

export interface FieldsNameOrder {
  [Fields.city]: number
  [Fields.company]: number
  [Fields.country]: number
  [Fields.firstName]: number
  [Fields.firstNamePronunciation]?: number
  [Fields.lastName]: number
  [Fields.lastNamePronunciation]?: number
  [Fields.phone]: number
  [Fields.postalCode]: number
  [Fields.postalCodeAutocomplete]: number
  [Fields.publicCompanyName]: number
  [Fields.stateOrProvince]: number
  [Fields.street]: number
  [Fields.street2]: number
  [Fields.streetAutocomplete]: number
}

export type FieldNameStyle = keyof FieldsNameStyle
export type FieldNameOrder = keyof FieldsNameOrder
