export default {
  firstName: {
    id: 'address_fields_first_name',
    defaultMessage: 'First name',
  },
  firstNamePronunciation: {
    id: 'address_fields_kana_first_name',
    defaultMessage: 'First name (Kana)',
  },
  lastName: {
    id: 'address_fields_last_name',
    defaultMessage: 'Last name',
  },
  lastNamePronunciation: {
    id: 'address_fields_kana_last_name',
    defaultMessage: 'Last name (Kana)',
  },
  companyOptional: {
    id: 'address_fields_company',
    defaultMessage: 'Company - Optional',
  },
  country: {
    id: 'address_fields_country',
    defaultMessage: 'Country',
  },
  stateOrProvince: {
    id: 'address_fields_state_or_province',
    defaultMessage: 'State',
  },
  street: {
    id: 'address_fields_street',
    defaultMessage: 'Address (number and name)',
  },
  street2: {
    id: 'address_fields_street_2',
    defaultMessage: 'Address 2 (building, code...) - Optional',
  },
  postalCode: {
    id: 'address_fields_postal_code',
    defaultMessage: 'ZIP code',
  },
  city: {
    id: 'address_fields_city',
    defaultMessage: 'City',
  },
  legal: {
    id: 'address_fields_legal',
    defaultMessage: 'You need to accept the conditions',
  },
  required: {
    id: 'address_fields_required',
    defaultMessage: 'This field is required',
  },
  invalidLength: {
    id: 'address_fields_invalid_length',
    defaultMessage: 'This field is too long',
  },
  publicCompanyName: {
    id: 'address_fields_public_company_name',
    defaultMessage: 'Public company name',
  },
  publicCompanyNameInfoText: {
    id: 'address_fields_public_company_name_info_text',
    defaultMessage: 'This name will be visible to your customers.',
  },
  companyNameHidden: {
    id: 'address_fields_company_hidden',
    defaultMessage: 'Add company name',
  },
  street2Hidden: {
    id: 'address_fields_street_2_hidden',
    defaultMessage: 'Add apartment, suite, etc',
  },
}
